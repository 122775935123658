.login-register {
	margin: 0;

	.user-page {
		width: 100%;
		height: 100%;
		.content {
			padding: 70px 10px 10px 10px;
			display: flex;
			flex-direction: column;
			position: relative;

			h1 {
				color: #576D7D;
			}

			#logout-button {
				position: absolute;
				top: 0px;
				right: 10px;
			}

		}
	}
}

@media (min-width: 600px) {
	.login-register {
		.user-page {
			.header {
				height: 250px;
			}

			.content {
				#logout-button {
					position: absolute;
					top: 0px;
					right: 40px;
				}
			}
		}
	}
}