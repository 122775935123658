.login-header{
	height: 350px;
	background-color: #576D7D;
	display: flex;
	justify-content: center;
	align-items: center;
	.logo{
		width: 250px;
		svg{	
			g{
				path{
					fill: white
				}
			}
		}
	}
}