#error{
	margin: 0;
	.content{
		background-color: white;
		color:  #576D7D;
		padding: 70px 30px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		h1{
			line-height: 1em;
			text-align: center;
		}
		// .button{
		// 	margin-top: 40px;
		// 	border: none;
		// 	padding: 10px 17px;
		// 	background-color: #576D7D;
		// 	a{
		// 		text-decoration: none;
		// 		color: white;
		// 	}
		// 	&:hover{
		// 		background-color: #667b8a;
		// 		transition: 0.2s;		
		// 	}
		// }
	}
}
