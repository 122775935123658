.button{
	margin-top: 40px;
	border: none;
	padding: 10px 17px;
	background-color: #576D7D;
	color: white;
	a{
		text-decoration: none;
		color: white;
	}
	&:hover{
		background-color: #667b8a;
		transition: 0.2s;		
	}
}