.filter {
    background-color: whitesmoke;
    padding: 0.5rem;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            padding: 0;
            margin: 1rem 0;
            span {
                display: inline-block;
                min-width: 10rem;
            }
        }
    }

    h2 {
        color: #576D7D;
    }

}

.filterbox {
    display: block;
    max-width: 15rem;
    // background-color: red;
    background-color:#f3f3f3;
    padding: 1rem;
}

table {
    thead {
        tr {
            position: relative;
            input[type=text] {
                display: inline-block;
                padding: 0.5rem 1rem;
                min-width: 10px;
                max-width: 100%;
                width: 100%;
            }
        }
    }
}