.downloads {
    width: 100%;

    .download {
        padding-left: 10px;
        background-color: #f3f3f3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 25px solid white;
        color: black;
        position: relative;

        .last-modified-date {
            position: absolute;
            top: -20px;
            right: 0;
            font-size: 0.7em;
            color: gray;
        }

        .download-button {
            text-decoration: none;

            span {
                display: none;
            }

            svg {
                padding: 10px;
                background-color: #576D7D;
                fill: white;
                width: 23px;
                height: 23px;
            }

            &:hover {

                span,
                svg {
                    background-color: #667b8a;
                    transition: 0.2s;
                }
            }
        }
    }

    #no-file {
        color: gray;
    }
}


@media (min-width: 600px) {
    .download {
        border-bottom: 5px solid white;

        .last-modified-date {
            right: -90px;
            top: 40%;
        }

        .download-button {
            display: flex;
            align-items: center;
            background-color: #576D7D;

            span {
                display: block;
                color: white;
                padding: 10px;
            }
        }
    }
}

@media (min-width: 1250px) {
    .downloads {
        width: 45%;
    }
}