.login-portal{
	font-family: Lato;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s;
	.LoginRegisterPro{
		width: 100%;
		h2{
			color: #576D7D;
		}
		.LoginForm{
			padding: 70px 20px;
			.Inputfields{
				.Inputfield{
					background-color: white;
					border: none;
					padding: 10px 0;
					width: 100%;
					.InputfieldContent{
						input{
							padding: 10px;
							width: 100%;
							border: none;
							border-bottom: 1px solid black;
						}

						button{
							border: none;
							padding: 10px 17px;
							background-color: #576D7D;
							color: white;
							a{
								text-decoration: none;
							}
							&:hover{
								background-color: #667b8a;
								transition: 0.2s;		
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 600px){
	.LoginRegisterPro{
		padding: 0 20%;
		}
}

@media (min-width: 800px){
	.LoginRegisterPro{
		padding: 0 30%;
	}
}

@media (min-width: 1250px){
	.LoginRegisterPro{
		padding: 0 35%;
	}
}

@media (min-width: 1625px){
	.LoginRegisterPro{
		padding: 0 37%;
	}
}