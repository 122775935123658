@import url(http://fonts.googleapis.com/css?family=Lato:400,400italic,700,700italic,300,300italic,900,900italic);

.container {
    
}

table {
    border: none;
    border-spacing: 0;

    th,
    td {
        padding: 10px 13px;
        border: none;
    }

    tr {
        &:nth-child(even) {
            td {
                background-color: white;
            }
        }
    }

    th {
        background-color: #576D7D;
        color: white;
        border-right: 1px solid white;
    }

    td {
        background-color: #f3f3f3;
        border-right: 1px solid white;
    }
}